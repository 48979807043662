// App.js
import React, { useContext, useEffect, useState } from 'react';
import { Route, Switch, Router } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Auth from 'Auth';
import Authorization from 'Authorization';
import LoadingView from 'views/loading';
import MainLayout from 'layouts/MainLayout';
import Login from 'views/auths/login';
import Register from 'views/auths/register';
import history from 'browserHistory';
import { selectLoading } from 'redux/selectors/index';
import { sendTokenViaSocket } from 'assets/js/common';
import { SocketContext } from './SocketIO';

window.Apex = {
  chart: {
    foreColor: '#ccc',
    toolbar: {
      show: false,
    },
  },
  stroke: {
    width: 3,
  },
  dataLabels: {
    enabled: false,
  },
  tooltip: {
    theme: 'dark',
  },
  grid: {
    borderColor: '#535A6C',
    xaxis: {
      lines: {
        show: true,
      },
    },
  },
};

function App() {
  const socket = useContext(SocketContext);
  const loading = useSelector(selectLoading);
  const [connected, setConnected] = useState(true);
  const [isInitialConnect, setIsInitialConnect] = useState(true);

  if (isInitialConnect && !window.lastInitialConnect) {
    window.lastInitialConnect = Date.now()
  }

  useEffect(() => {
    // Send the token after 1.5 seconds if not connected by then.
    const tokenTimer = setTimeout(() => {
      sendTokenViaSocket(socket);
    }, 1500);
  
    // We'll store the disconnect timer so we can clear it if needed.
    let disconnectTimer;
  
    const onConnect = () => {
      sendTokenViaSocket(socket);
      clearTimeout(tokenTimer);
  
      // If a disconnect timer was set, clear it since we're back online.
      if (disconnectTimer) {
        clearTimeout(disconnectTimer);
        disconnectTimer = null;
      }
  
      // Mark as connected.
      setConnected(true);
  
      // Refresh chart if this isn't the first connection.
      if (!isInitialConnect) {
        window.chartShouldRefresh = true;
      }
    };
  
    const onDisconnect = () => {
      // Delay setting the state to disconnected by 5 seconds.
      disconnectTimer = setTimeout(() => {
        setConnected(false);
        setIsInitialConnect(false);
      }, 5000);
    };
  
    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
  
    return () => {
      clearTimeout(tokenTimer);
      if (disconnectTimer) clearTimeout(disconnectTimer);
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
    };
  }, [socket, isInitialConnect]);
  

  const isLoading = !connected || loading;

  return (
    <>
      {isLoading && <LoadingView />}
      <Auth loading={isLoading}>
        <Router history={history}>
          <Authorization connected={connected}>
            <Switch>
              <Route path="/login" exact component={Login} />
              <Route path="/register" exact component={Register} />
              <Route path="/" component={MainLayout} />
            </Switch>
          </Authorization>
        </Router>
      </Auth>
    </>
  );
}

export default App;
